import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";  // Import useNavigate from react-router-dom

import "react-toastify/dist/ReactToastify.css";
import "./SearchDash.css";

const ITEMS_PER_PAGE = 6;
const API_URL = process.env.REACT_APP_API_URL;

const SearchDash: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<any[]>([]);

  const [phrases, setPhrases] = useState<any[]>([]);
  const [visibleItems, setVisibleItems] = useState<number>(ITEMS_PER_PAGE);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();  // Initialize useNavigate

  // Redirect to login if not authenticated
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");  // Redirect to login page if no token is found
    }
  }, [navigate]);

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const handleSearch = async () => {
    const token = localStorage.getItem("token");
  
    // Cek apakah token ada, jika tidak tampilkan pesan error
    if (!token) {
      toast.error("Token tidak ditemukan. Silakan login terlebih dahulu.");
      return; // Menghentikan eksekusi lebih lanjut
    }
  
    setLoading(true);
    setVisibleItems(ITEMS_PER_PAGE);
    setPhrases([]);
    setResults([]);
  
    try {
      // Langkah 1: Mencari berdasarkan keyword
      const searchResponse = await axios.post(
        `${API_URL}/keywords/search`,
        JSON.stringify({ keyword: query }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );
  
      setResults(searchResponse.data.results || []);
      
      if (searchResponse.data.keyword_id) {
        // Langkah 2: Menganalisis sentimen
        await analyzeSentiments(searchResponse.data.keyword_id, token);
        // Langkah 3: Mengambil frasa yang dianalisis
        await fetchPhrases(searchResponse.data.keyword_id, token);
      }
  
      toast.success(searchResponse.data.message || "Pencarian selesai!");
    } catch (err: any) {
      toast.error(
        err.response?.data?.error || "Terjadi kesalahan saat melakukan pencarian."
      );
    } finally {
      setLoading(false);
    }
  };
  

  const analyzeSentiments = async (keywordId: number, token: string) => {
    try {
      await axios.post(
        `${API_URL}/sentiments/analyze/${keywordId}`,
        {},
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
    } catch (err: any) {
      toast.error(
        err.response?.data?.error || "An error occurred while analyzing sentiments."
      );
      throw err;
    }
  };

  const fetchPhrases = async (keywordId: number, token: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/sentiments/phrases/${keywordId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      setPhrases(response.data.phrases || []);
    } catch (err: any) {
      toast.error(
        err.response?.data?.error || "An error occurred while fetching phrases."
      );
    }
  };

  const handleLoadMore = () => {
    setVisibleItems((prev) => prev + ITEMS_PER_PAGE);
  };

 

  return (
    <div className="container">
      {/* Header */}
      <div className="text-center py-4 mb-4">
        <h1 className="text-primary">📊 Explore Content and Sentiments</h1>
        <p className="text-secondary">
          Enter a keyword to explore results and insights.
        </p>
      </div>

      {/* Search Bar */}
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="input-group mb-4 shadow-sm">
            <input
              type="text"
              className="form-control"
              placeholder="Enter your keyword..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={handleSearch}
              disabled={loading}
            >
              {loading ? "Searching..." : "Search"}
            </button>
          </div>
        </div>
      </div>

      {/* Loading State */}
      {loading && (
        <div className="loading-overlay">
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {/* Display Search Query Result */}
      {query && phrases.length > 0 && (
        <div className="mb-4">
          <h4 className="text-primary fw-bold">
            Berikut adalah hasil pencarian "<strong>{query}</strong>"
          </h4>
        </div>
      )}

      {/* Phrases Results */}
      <div className="row">
        {phrases.slice(0, visibleItems).map((phrase, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <div className="card border-0 shadow-sm h-100">
              {/* Display Image */}
              <img
                src={phrase.icon_url || "https://via.placeholder.com/150"} // Fallback to placeholder if no image
                alt="Content Thumbnail"
                className="card-img-top"
                style={{
                  objectFit: "cover",
                  height: "180px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              />
              <div className="card-body">
                {/* Header */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${phrase.domain}`} // Dynamic icon from domain
                      alt="Web Icon"
                      className="me-2"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "5px",
                      }}
                    />
                    <h5
                      className="card-title text-primary mb-0"
                      title={phrase.domain} // Tooltip for full domain
                    >
                      {truncateText(phrase.domain, 20)}
                    </h5>
                  </div>
                  <span
                    className={`badge ${
                      phrase.sentiment_type === "positive"
                        ? "bg-success"
                        : phrase.sentiment_type === "negative"
                        ? "bg-danger"
                        : "bg-secondary"
                    }`}
                  >
                    {phrase.sentiment.charAt(0).toUpperCase() + phrase.sentiment.slice(1)}
                  </span>
                </div>

                {/* Content */}
                <p className="card-text">
                  <strong>Phrase:</strong> {phrase.phrase}
                </p>
                <p className="card-text">
                  <strong>Content:</strong> {truncateText(phrase.content, 100)}
                </p>
              </div>
              <div className="card-footer text-end bg-transparent">
                {/* Cek apakah link ada */}
                <a 
                  href={phrase.link || "#"}  // Asumsikan link berada di `phrase.link`
                  target="_blank" 
                  rel="noopener noreferrer"  // Keamanan tambahan saat membuka tab baru
                >
                  <button className="btn btn-outline-primary btn-sm">
                    View Details
                  </button>
                </a>
              </div>

            </div>
          </div>
        ))}
      </div>

      {/* Load More */}
      {visibleItems < phrases.length && (
        <div className="text-center mt-3 mb-4">
          <button className="btn btn-primary" onClick={handleLoadMore}>
            Load More
          </button>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default SearchDash;
