// src/env.ts
export const ENV = {
    API_URL: process.env.REACT_APP_API_URL,
    PUBLIC_URL: process.env.PUBLIC_URL,
  } as const;
  
  // Validasi environment variables
  const validateEnv = () => {
    if (!ENV.API_URL) {
      console.error('REACT_APP_API_URL is not defined in environment variables');
    }
    if (!ENV.PUBLIC_URL) {
      console.warn('PUBLIC_URL is not defined, using default value');
    }
  };
  
  validateEnv();
  
  export default ENV;